<template>
  <el-dialog class="main-container" :title="$t('form.activity.chooseProduct')" :visible="visible" width="800px" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleDialogClose">
    <el-form ref="form" label-width="80px" :inline="true">
      <el-form-item>
        <el-input v-model="searchForm.data.name_or_name_en_i_cont" :placeholder="$t('form.activity.productName')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="searchForm.data.shop_category_items_shop_category_id_eq" :placeholder="$t('form.product.shopCategories')" clearable filterable>
          <el-option
            v-for="(item, index) in shopCategorieList"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="searchForm.data.shop_item_sub_categories_shop_sub_category_id_eq" :placeholder="$t('form.product.shopSubCategories')" clearable filterable>
          <el-option
            v-for="(item, index) in shopSubCategorieList"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchForm.data.shop_item_stocks_sku_id_i_cont" placeholder="SKU"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="searchForm.data.supplier_id_eq" :placeholder="$t('form.activity.supplier')" clearable filterable>
          <el-option
            v-for="(item, index) in supplierList"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="searchForm.data.shop_id_eq" :placeholder="$t('form.activity.shop')" clearable filterable>
          <el-option
            v-for="(item, index) in shopList"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearchClick">{{ $t('button.search') }}</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        ref="mainTable"
        class="interval-mb"
        :data="mainTable.data"
        style="width: 100%"
        max-height="400"
        v-loading="mainTable.loading">
        <el-table-column width="55">
          <template slot-scope="scope">
            <el-checkbox-group v-model="mainTable.selectedRow" @change="handleMainTableCheckboxGroupChange">
              <el-checkbox :label="scope.row.id" :disabled="!scope.row.addable">{{ '' }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column :label="$t('form.activity.productName')" width="320">
          <template slot-scope="scope">
            <div class="product-box">
              <div v-if="scope.row.image && scope.row.image !== 0" class="cover">
                <img :src="scope.row.image[0]" class="img">
              </div>
              <div class="info">
                <div class="title">{{ scope.row.name }}</div>
                <div class="desc">ID: {{ scope.row.id }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('form.activity.shop')" prop="shop.name"></el-table-column>
        <el-table-column :label="$t('form.activity.supplier')" prop="supplier.name"></el-table-column>
        <el-table-column :label="$t('form.activity.price')" width="120">
          <template slot-scope="scope">
            <div>{{ scope.row.price_text }}</div>
            <div>{{ scope.row.line_price_text }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('table.common.status')">
          <template slot-scope="scope">
            <div>{{ scope.row.online ? $t('form.activity.online') : $t('form.activity.offline') }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleMainTableSizeChange"
        @current-change="handleMainTableCurrentChange"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="mainTable.pagination.papeSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="mainTable.pagination.totalCount">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleDialogClose">{{ $t('button.cancel') }}</el-button>
      <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('form.activity.nextStep') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import dataDictionary from '@/utils/data-dictionary.js'
import * as promotionApi from '@/services/promotion.js'

export default {
  name: 'productListDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    setVisible: {
      type: Function,
      default: () => false
    },
    activity: {
      type: Object,
      default: () => {}
    },
    shopList: {
      type: Array,
      default: () => []
    },
    supplierList: {
      type: Array,
      default: () => []
    },
    shopCategorieList: {
      type: Array,
      default: () => []
    },
    shopSubCategorieList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dataDictionary,
      searchForm: {
        data: {
          name_or_name_en_i_cont: null,
          shop_id_eq: null
        }
      },
      mainTable: {
        loading: false,
        // 分页
        pagination: {
          papeSize: 50,
          current: 1,
          totalCount: 0
        },
        // 数据
        data: [],
        selectedRow: []
      }
    }
  },
  watch: {
    visible (newV, oldV) {
      const _this = this
      if (newV) {
        _this.mainTable.selectedRow = []
        _this.mainTable.pagination.papeSize = 50
        _this.mainTable.pagination.current = 1
        _this.mainTable.pagination.totalCount = 0
        _this.searchForm.data.name_or_name_en_i_cont = null
        _this.searchForm.data.shop_id_eq = null
        _this.requestMainTableData()
      }
    }
  },
  mounted () {},
  methods: {
    dateFormat (date) {
      return Utils.dateFormat('YYYY-mm-dd HH:MM:SS', date)
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    setMainTableLoading (show) {
      const _this = this
      show ? _this.mainTable.loading = true : _this.mainTable.loading = false
    },
    requestMainTableData () {
      const _this = this
      _this.setMainTableLoading(true)
      promotionApi.getShopItemsSimpleList({
        page: _this.mainTable.pagination.current,
        per_page: _this.mainTable.pagination.papeSize,
        shop_activity_id: _this.activity.id,
        ..._this.searchForm.data
      }).then(res => {
        _this.setMainTableLoading(false)
        const resData = res.data
        _this.mainTable.pagination.totalCount = resData.pagination.total_count
        _this.mainTable.data = resData.shop_items
      }).catch(() => _this.setMainTableLoading(false))
    },
    handleMainTableSizeChange (val) {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.mainTable.pagination.papeSize = val
      _this.requestMainTableData()
    },
    handleMainTableCurrentChange (val) {
      const _this = this
      _this.mainTable.pagination.current = val
      _this.requestMainTableData()
    },
    handleDialogClose () {
      const _this = this
      _this.setVisible(false)
    },
    handleSearchClick () {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.requestMainTableData()
    },
    handleMainTableCheckboxGroupChange (val) {
      const _this = this
      if (val.length > 1) {
        _this.mainTable.selectedRow = [val[1]]
      }
    },
    handleMainFormSubmitClick () {
      const _this = this
      if (_this.mainTable.selectedRow.length === 0) {
        _this.$message({
          message: _this.$t('form.activity.submitProductListDialogWarning1'),
          type: 'warning'
        })
      } else {
        _this.$emit('onSubmitClick', _this.mainTable.selectedRow[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  .interval-mb {
    margin-bottom: 12px;
  }
  .product-box {
    display: flex;
    align-items: center;
    .cover {
      flex-shrink: 0;
      width: 30%;
      margin-right: 12px;
      .img {
        object-fit: contain;
        display: block;
        width: 100px;
        height: 100px;
      }
    }
    .info {
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      .title {
        margin-bottom: 12px;
        word-break: break-all;
      }
    }
  }
}
</style>
