<template>
  <div class="pcaap-container">
    <el-card>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" @click="handleAddProductClick">{{ $t('form.activity.addActivityProduct') }}</el-button>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col>
          <el-button type="primary" :disabled="mainTable.multipleSelection.length < 1" @click="handleBatchOnlineClick">{{ $t('text.batchOnline') }}</el-button>
          <el-button type="primary" :disabled="mainTable.multipleSelection.length < 1" @click="handleBatchOfflineClick">{{
            $t('text.batchOffline')
          }}</el-button>
          <el-button type="primary" :disabled="mainTable.multipleSelection.length < 1" @click="handleBatchDestroyClick">{{
            $t('text.batchDestroy')
          }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
            ref="mainTable"
            :data="mainTable.data"
            v-loading="mainTable.loading"
            style="width: 100%"
            max-height="700"
            @selection-change="handleMainTableSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column :label="$t('form.activity.productName')" width="320">
              <template slot-scope="scope">
                <div class="product-box">
                  <div v-if="scope.row.cover_image_url && scope.row.cover_image_url.length !== 0" class="cover">
                    <img :src="scope.row.cover_image_url" class="img" />
                  </div>
                  <div class="info">
                    <div class="title">{{ scope.row.shop_item.name }}</div>
                    <div class="desc">ID: {{ scope.row.shop_item.id }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('form.activity.shop')" prop="shop_item.shop.name" />
            <el-table-column :label="$t('form.activity.supplier')" prop="shop_item.supplier.name" />
            <el-table-column :label="$t('form.activity.sameUserPurchaseLimit')" prop="maximum_limit_per_user" width="160" />
            <el-table-column :label="$t('form.activity.participatingModel')" width="320">
              <template slot-scope="scope">
                <div v-for="(item, index) in scope.row.item_stocks" :key="index">
                  <div>{{ item.name }}</div>
                  <div
                    v-if="
                      activityDetail.activity_type === dataDictionary['001']['004'].code ||
                      activityDetail.activity_type === dataDictionary['001']['005'].code ||
                      activityDetail.activity_type === dataDictionary['001']['006'].code
                    "
                  >
                    {{
                      $t('table.activity.activityProductSellingPriceAndCampaignPrice', {
                        sellingPrice: item.selling_price / 100,
                        campaignPrice: item.campaign_price / 100,
                      })
                    }}
                  </div>
                  <div v-else>{{ $t('table.activity.activityProductSellingPrice', { sellingPrice: item.selling_price / 100 }) }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('form.activity.online')" width="120">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.online" @change="() => handleMainTableOnlineSwitchClick(scope.row)" />
              </template>
            </el-table-column>
            <el-table-column :label="$t('table.common.operate')" fixed="right" width="220">
              <template slot-scope="scope">
                <el-button class="margintop10" size="mini" type="primary" @click="() => handleMainTableEditClick(scope.row)">{{ $t('button.edit') }}</el-button>
                <el-button class="margintop10" size="mini" type="danger" @click="() => handleMainTableDelClick(scope.row)">{{ $t('button.delete') }}</el-button>
                <el-button class="margintop10" size="mini" @click="() => handleMainTableMttClick(scope.row)">{{ $t('button.moveToTop') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleMainTableSizeChange"
            @current-change="handleMainTableCurrentChange"
            :page-sizes="[50, 100, 150, 200]"
            :page-size="mainTable.pagination.papeSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="mainTable.pagination.totalCount"
          >
          </el-pagination>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" :disabled="activeStep <= 0" @click="handleMainOkClick">{{ $t('button.confirm') }}</el-button>
        </el-col>
      </el-row>
    </el-card>
    <s-product-list-dialog
      :visible="dialog.productList.visible"
      :setVisible="setProductListDialogVisible"
      :activity="activityDetail"
      :shopList="shopList"
      :supplierList="supplierList"
      :shopCategorieList="shopCategorieList"
      :shopSubCategorieList="shopSubCategorieList"
      @onSubmitClick="handleProductListDialogSubmitClick"
    />
    <s-product-info-dialog
      v-if="dialog.productInfo.visible"
      :visible="dialog.productInfo.visible"
      :setVisible="setProductInfoDialogVisible"
      :type="dialog.productInfo.type"
      :activity="activityDetail"
      :productId="selectedProductId"
      :data="dialog.productInfo.data"
      :stock="dialog.productInfo.stock"
      @onSuccess="handleAddProductSuccess"
      @onCancel="handleAddProductCancel"
    />
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import loading from '@/utils/loading.json'
import dataDictionary from '@/utils/data-dictionary.js'
import SProductInfoDialog from './components/productInfoDialog/index.vue'
import SProductListDialog from './components/productListDialog/index.vue'
import * as promotionApi from '@/services/promotion.js'
import * as suppliersApi from '@/services/suppliers.js'
import * as shopCategoriesApi from '@/services/shop-categories.js'
import * as shopSubCategoriesApi from '@/services/shop-sub-categories.js'
import * as shopActivitiesApi from '@/services/shop-activities.js'

export default {
  components: {
    SProductInfoDialog,
    SProductListDialog
  },
  props: ['activeStep', 'setActiveStep'],
  data () {
    return {
      dataDictionary,
      routeParams: null,
      activityDetail: null,
      shopList: [],
      supplierList: [],
      shopCategorieList: [],
      shopSubCategorieList: [],
      selectedProductId: null,
      mainTable: {
        loading: false,
        // 分页
        pagination: {
          papeSize: 50,
          current: 1,
          totalCount: 0
        },
        multipleSelection: [],
        // 数据
        data: []
      },
      mainForm: {
        data: {
          online: false, // 上架
          homepage_display: false, // 是否首页展示
          name: '', // 活动动名
          name_en: '', // 活动名(英文)
          date: null,
          begin_date: null, // 开始时间
          end_date: null, // 结束时间
          remote_image_url: null
        }
      },
      dialog: {
        productList: {
          visible: false
        },
        productInfo: {
          type: 0, // 0 新增 1 编辑
          visible: false,
          data: null,
          stock: []
        }
      }
    }
  },
  created () {
    const _this = this
    _this.routeParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.rowDrop()
    const initRequest1 = promotionApi.getShopsSimpleList().then(res => {
      _this.shopList = res.data.shops
    }).catch(err => err)
    const initRequest2 = suppliersApi.getSimpleList().then(res => {
      _this.supplierList = res.data.suppliers
    }).catch(err => err)
    const initRequest3 = shopCategoriesApi.getSimpleList().then(res => {
      _this.shopCategorieList = res.data.shop_categories
    }).catch(err => err)
    const initRequest4 = shopSubCategoriesApi.getSimpleList().then(res => {
      _this.shopSubCategorieList = res.data.shop_sub_categories
    }).catch(err => err)
    const initRequest5 = promotionApi.getShopActivitiesIdPreview(_this.routeParams.activityId).then(res => {
      _this.activityDetail = res.data.shop_activity
      _this.requestMainTableData()
    }).catch(err => err)
    _this.setGlobalLoading(true)
    Promise.all([initRequest1, initRequest2, initRequest3, initRequest4, initRequest5]).then(() => _this.setGlobalLoading(false)).catch(() => _this.setGlobalLoading(false))
  },
  methods: {
    // 行拖拽
    rowDrop () {
      const _this = this
      Sortable.create(_this.$refs.mainTable.$el.children[2].children[0].children[1], {
        animation: 150,
        onEnd: ev => {
          const mainTableData = _this.mainTable.data
          const oldIndex = ev.oldIndex
          const newIndex = ev.newIndex
          const requestParams = {}
          if (newIndex !== mainTableData.length - 1) {
            if (oldIndex - newIndex === 1) {
              requestParams.above_item_id = mainTableData[newIndex].id
            } else {
              if (newIndex === 0) {
                requestParams.above_item_id = mainTableData[0].id.value
              } else {
                requestParams.above_item_id = mainTableData[newIndex + 1].id
              }
            }
          } else {
            requestParams.below_item_id = mainTableData[mainTableData.length - 1].id
          }
          _this.setGlobalLoading(true)
          promotionApi.putShopActivitiesIdItemsIdRank(_this.activityDetail.id, mainTableData[oldIndex].id, requestParams).then(res => {
            _this.$refs.mainTable.doLayout()
            _this.requestMainTableData().then(() => {
              _this.setGlobalLoading(false)
            }).catch(() => _this.setGlobalLoading(false))
            _this.setGlobalLoading(false)
          }).catch(() => _this.setGlobalLoading(false))
        }
      })
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    setMainTableLoading (show) {
      const _this = this
      show ? _this.mainTable.loading = true : _this.mainTable.loading = false
    },
    setProductInfoDialogVisible (show) {
      const _this = this
      _this.dialog.productInfo.visible = show
    },
    setProductListDialogVisible (show) {
      const _this = this
      _this.dialog.productList.visible = show
    },
    requestMainTableData () {
      const _this = this
      _this.setMainTableLoading(true)
      return promotionApi.getShopActivitiesIdItems(_this.activityDetail.id, {
        page: _this.mainTable.pagination.current,
        per_page: _this.mainTable.pagination.papeSize
      }).then(res => {
        _this.setMainTableLoading(false)
        const resData = res.data
        _this.mainTable.data = []
        _this.mainTable.pagination.totalCount = resData.pagination.total_count
        _this.$nextTick(() => {
          _this.mainTable.data = resData.objects
        })
      }).catch(() => _this.setMainTableLoading(false))
    },
    handleProductListDialogSubmitClick (selectedProductId) {
      const _this = this
      _this.dialog.productInfo.type = 0
      _this.selectedProductId = selectedProductId
      // 秒杀、拼团、砍价
      if (_this.activityDetail.activity_type === dataDictionary['001']['004'].code || _this.activityDetail.activity_type === dataDictionary['001']['005'].code || _this.activityDetail.activity_type === dataDictionary['001']['006'].code) {
        _this.setGlobalLoading(true)
        promotionApi.getShopActivitiesIdStocksSimpleList(selectedProductId).then(res => {
          _this.setGlobalLoading(false)
          _this.dialog.productInfo.stock = res.data.shop_item_stocks
          _this.setProductListDialogVisible(false)
          _this.setProductInfoDialogVisible(true)
        }).catch(() => _this.setGlobalLoading(false))
      } else {
        _this.setProductListDialogVisible(false)
        _this.setProductInfoDialogVisible(true)
      }
    },
    handleAddProductClick () {
      const _this = this
      _this.dialog.productList.visible = true
    },
    handleAddProductSuccess () {
      const _this = this
      _this.selectedProductId = null
      _this.setProductInfoDialogVisible(false)
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.requestMainTableData()
    },
    handleAddProductCancel () {
      const _this = this
      _this.selectedProductId = null
    },
    handleMainTableEditClick (data) {
      const _this = this
      _this.dialog.productInfo.type = 1
      _this.selectedProductId = data.id
      _this.setGlobalLoading(true)
      // 秒杀、拼团、砍价
      if (_this.activityDetail.activity_type === dataDictionary['001']['004'].code || _this.activityDetail.activity_type === dataDictionary['001']['005'].code || _this.activityDetail.activity_type === dataDictionary['001']['006'].code) {
        promotionApi.getShopActivitiesIdStocksSimpleList(data.shop_item.id).then(res => {
          _this.dialog.productInfo.stock = res.data.shop_item_stocks
          return true
        }).then(() => {
          promotionApi.getShopActivitiesIdItemsIdPreview(_this.activityDetail.id, data.id).then(res => {
            _this.setGlobalLoading(false)
            _this.dialog.productInfo.data = res.data.shop_activity_item
            _this.setProductInfoDialogVisible(true)
          }).catch(() => _this.setGlobalLoading(false))
        }).catch(() => _this.setGlobalLoading(false))
      } else {
        promotionApi.getShopActivitiesIdItemsIdPreview(_this.activityDetail.id, data.id).then(res => {
          _this.setGlobalLoading(false)
          _this.dialog.productInfo.data = res.data.shop_activity_item
          _this.setProductInfoDialogVisible(true)
        }).catch(() => _this.setGlobalLoading(false))
      }
    },
    handleMainTableSizeChange (val) {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.mainTable.pagination.papeSize = val
      _this.requestMainTableData()
    },
    handleMainTableCurrentChange (val) {
      const _this = this
      _this.mainTable.pagination.current = val
      _this.requestMainTableData()
    },
    handleMainTableDelClick (data) {
      const _this = this
      _this.$confirm(_this.$t('text.confirmDeleteThisItem'), _this.$t('text.confirmation'), {
        type: 'warning'
      }).then(() => {
        _this.setGlobalLoading(true)
        promotionApi.deleteShopActivitiesIdItemsId(_this.activityDetail.id, data.id).then(res => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          if (_this.mainTable.data.length === 1) {
            _this.mainTable.pagination.current--
            if (_this.mainTable.pagination.current < 1) {
              _this.mainTable.pagination.current = 1
            }
          }
          _this.requestMainTableData()
        }).catch(() => _this.setGlobalLoading(false))
      }).catch(err => err)
    },
    handleMainTableMttClick (data) {
      const _this = this
      _this.setGlobalLoading(true)
      promotionApi.putShopActivitiesIdItemsIdRank(_this.activityDetail.id, data.id).then(res => {
        _this.setGlobalLoading(false)
        _this.requestMainTableData()
      }).catch(() => _this.setGlobalLoading(false))
    },
    handleMainOkClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainTableOnlineSwitchClick (data) {
      const _this = this
      _this.setGlobalLoading(true)
      promotionApi.putShopActivitiesIdItemsId(_this.activityDetail.id, data.id, {
        shop_activity_item: {
          online: data.online
        }
      }).then(res => {
        _this.setGlobalLoading(false)
        _this.$message({
          type: 'success',
          message: _this.$t('message.operateSuccess')
        })
      }).catch(() => _this.setGlobalLoading(false))
    },
    handleMainTableSelectionChange (val) {
      const _this = this
      _this.mainTable.multipleSelection = val
    },
    handleBatchOnlineClick () {
      const _this = this
      _this.$confirm(_this.$t('text.confirmBatchOnline'), _this.$t('text.confirmation'), {
        type: 'warning'
      }).then(() => {
        _this.setGlobalLoading(true)
        shopActivitiesApi.postSAIBatchOnline(_this.activityDetail.id, {
          ids: _this.mainTable.multipleSelection.map(item => item.id)
        }).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.requestMainTableData()
        }).catch(() => {
          _this.setGlobalLoading(false)
        })
      }).catch(err => err)
    },
    handleBatchOfflineClick () {
      const _this = this
      _this.$confirm(_this.$t('text.confirmBatchOffline'), _this.$t('text.confirmation'), {
        type: 'warning'
      }).then(() => {
        _this.setGlobalLoading(true)
        shopActivitiesApi.postSAIBatchOffline(_this.activityDetail.id, {
          ids: _this.mainTable.multipleSelection.map(item => item.id)
        }).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.requestMainTableData()
        }).catch(() => {
          _this.setGlobalLoading(false)
        })
      }).catch(err => err)
    },
    handleBatchDestroyClick () {
      const _this = this
      _this.$confirm(_this.$t('text.confirmBatchDestroy'), _this.$t('text.confirmation'), {
        type: 'warning'
      }).then(() => {
        _this.setGlobalLoading(true)
        shopActivitiesApi.postSAIBatchDestroy(_this.activityDetail.id, {
          ids: _this.mainTable.multipleSelection.map(item => item.id)
        }).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.requestMainTableData()
        }).catch(() => {
          _this.setGlobalLoading(false)
        })
      }).catch(err => err)
    }
  }
}
</script>

<style lang="scss" scoped>
.pcaap-container {
  .interval {
    margin-bottom: 12px;
  }
  .product-box {
    display: flex;
    align-items: center;
    .cover {
      flex-shrink: 0;
      width: 40%;
      margin-right: 12px;
      .img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .info {
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      .title {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: bold;
        word-break: break-all;
      }
    }
  }
}
</style>
